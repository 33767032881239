import React, { FC } from "react";
import TitleHeader from "./titleHeader";
import { useSelector } from "react-redux";
import { RootState } from "@/lib/store";

const LogoHeader: FC = () => {
  const screensDetails = useSelector((state: RootState) => state.screenDetails);
  return (
    <div className="navbar">
      {screensDetails.showLogo === true && (
        <p className="navbar-logo">
          micromojo <span className="navbar-logo-span">®</span>
        </p>
      )}

      {screensDetails.showHeader && <TitleHeader />}
    </div>
  );
};

export default LogoHeader;
